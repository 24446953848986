.thoughtful {
  background-color: var(--secondary-color);
  padding-top: 36px;
  margin: auto;
}
.thoughtful__title {
  color: var(--white-color);
  text-align: center;
  font-weight: 700;
}
.thoughtful-menu {
  display: grid;
  grid-template-columns: repeat(4, 270px);
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}
.thoughtful-active {
  background-size: cover;
  background-position: center;
  width: 178px;
  margin: auto;
  margin-bottom: 0;
  background-image: url("/public/media/active.webp");
}
.thoughtful-menu__item {
  display: grid;
  grid-template-rows: 95px 1fr;
  padding: 20px 0px;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  justify-content: center;
  place-items: center;
}
.thoughtful-active .thoughtful-menu__item--title {
  color: var(--gray-color);
  height: 20px;
}
.thoughtful-menu__item--title {
  color: var(--white-color);
  text-wrap: wrap;
}

.thoughtful-main {
  width: 100%;
  padding: 20px 64px 170px;
  background-color: var(--gray-color-100);
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 16px;
  -webkit-clip-path: ellipse(82% 90% at 50% 10%);
}

.thoughtful-item {
  grid-column: 1 / -1;
  margin: 0 auto;
  position: relative;
  min-height: 430px;
}
.thoughtful-cover__wording {
  position: absolute;
  top: 32px;
  right: 32px;
  left: 32px;
  font-size: 28px;
  font-weight: 700;
  text-shadow: 0px 0px 2px black;
  color: white;
}
.thoughtful-cover__desktop-img {
  margin: 0 auto;
  display: block;
  border-radius: 20px;
  object-fit: cover;
  height: 500px;
  object-position: top center;
}
.thoughtful-cover__mobile-img {
  display: none;
}

@media (max-width: 1200px) {
  .thoughtful-item {
    grid-column: 1 / -1;
  }
  .thoughtful-cover__desktop-img {
    width: 800px;
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .thoughtful-menu {
    grid-template-columns: repeat(4, 90px);
  }
  .thoughtful-active {
    width: 100%;
  }
  .thoughtful-menu__item {
    padding: 8px;
    grid-template-rows: 60px 1fr;
    justify-items: center;
  }
  .thoughtful-menu__item > svg {
    height: 25px;
  }
  .thoughtful-menu__item--title {
    color: var(--white-color);
    text-wrap: wrap;
    font-size: medium;
  }
  .thoughtful-cover__desktop-img {
    width: 700px;
    height: 350px;
  }
}

@media (max-width: 800px) {
  .thoughtful-main {
    padding: 20px 20px 80px;
    -webkit-clip-path: ellipse(138% 90% at 50% 10%);
    max-width: 100vw;
    grid-template-columns: repeat(8, 1fr);
  }
  .thoughtful-item {
    min-height: 100px;
    grid-gap: 20px;
  }
  .thoughtful-menu__item > svg {
    height: 25px;
    margin-bottom: -20px;
  }
  .thoughtful-cover__wording {
    font-size: 20px;
  }
  .thoughtful-cover__desktop-img {
    width: 100%;
    height: 350px;
  }
}
@media (max-width: 600px) {
  .thoughtful-cover__desktop-img {
    width: 100%;
    height: 350px;
  }
}

@media (max-width: 480px) {
  .thoughtful-cover__wording {
    position: absolute;
    top: 16px;
    left: 16px;
  }
}
