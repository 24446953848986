.menu-item {
  color: --secondary-color;
}
.header-container {
  z-index: 100;
}

.menu-overlay {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menu-open {
  transform: translateX(0);
  opacity: 1;
}

.menu-closed {
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none;
}
