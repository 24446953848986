h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 31.2px;
}
.button-tertiary {
  background-color: var(--white-color);
  color: var(--gray-color);
  opacity: 0;
}
.button-normal {
  padding: 12px 20px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
button,
.button {
  border: none;
  border-radius: 50px;
  font-weight: 500;
  width: max-content;
  cursor: pointer;
  font-family: var(--font-heading-family);
  transition: all 0.2s;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  justify-content: center;
}

.feature-main {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 40px;
  padding: 0 40px;
}
.component-feature .badge {
  grid-column: 2 / 5;
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 16px;
}

.component-feature .badge__button--watchful.badge__button--active-0 {
  background-color: var(--primary-color-700);
  border: none;
  color: var(--white-color);
}
.component-feature .badge__button--watchful.badge__button--active-1 {
  background-color: var(--secondary-color);
  border: none;
  color: var(--white-color);
}
.component-feature .badge__button--watchful.badge__button--active-2 {
  /* background-color: var(--secondary-color); */
  background-color: var(--tertiary-color);
  color: var(--white-color);
  border: none;
}

.component-feature .badge__button {
  padding: 13px 20px;
  border-radius: 50px;
  display: flex;
  grid-gap: 12px;
  align-items: center;
  font-size: 28px;
  line-height: 24px;
  color: var(--primary-color-700);
  cursor: pointer;
  background-color: var(--white-color);
  border: 1px solid var(--primary-color-700);
}

.component-feature .feature-item-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  background-color: transparent;
  transition: all 0.2s;
  border-radius: 20px;
  align-items: flex-start;
  justify-content: space-between;
}
.content__description {
  opacity: 0;
}

.component-feature .feature-box {
  grid-column: 5 / span 9;
  overflow: hidden;
}

.component-feature .wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.component-feature .feature-item-stretch {
  grid-column: 1 / span 2;
}

.component-feature .feature-item {
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  height: 300px;
}

.component-feature .feature-item__video {
  height: 100%;
}

.component-feature .feature-item__video--desktop {
  display: block;
}

.component-feature .feature-item__img--desktop {
  display: block;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}
.component-feature .feature-item__img--mobile {
  display: none;
}

.content__title {
  text-shadow: 0px 0px 4px black;
  font-size: 2.25rem;
  text-align: left;
  line-height: 2.25rem;
  color: white;
}

@media (min-width: 1600px) {
  .component-feature .badge {
    grid-column: 2 / 4;
  }
  .component-feature .feature-box {
    grid-column: 4 / span 9;
  }
}

@media (min-width: 800px) {
  .component-feature .feature-item-content-ai:hover {
    background-color: var(--primary-color-700);
  }
  .component-feature .feature-item-content-alerts:hover {
    background-color: var(--secondary-color);
  }
  .component-feature .feature-item-content-watchful:hover {
    background-color: var(--tertiary-color);
  }
  .component-feature .feature-item-content:hover .content__title {
    color: var(--white-color) !important;
    text-shadow: none;
  }
  .component-feature .feature-item-content:hover .content__description {
    color: var(--white-color) !important;
    text-align: left;
    font-size: 24px;
  }
  .component-feature .feature-item-content:hover .content__description,
  .component-feature .feature-item-content:hover .content__button {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .component-feature .badge {
    grid-column: 1 / 4;
  }
  .component-feature .feature-box {
    grid-column: 4 / span 9;
  }
  .component-feature .feature-item {
    height: 280px;
  }
}

@media (max-width: 1200px) {
  .feature-main {
    padding: 0 20px;
    grid-column-gap: 0px;
  }
  .component-feature .badge {
    grid-column: 0 / 3;
  }
  .component-feature .feature-box {
    grid-column: 4 / span 9;
  }

  .component-feature .feature-item {
    height: 280px;
  }
  .badge__button {
    font-size: 18px;
  }
}
@media (max-width: 1000px) {
  .feature-main {
    grid-column-gap: 16px;
  }
  .component-feature .feature-box {
    grid-column: 6 / span 14;
  }
  .component-feature .badge {
    grid-column: 1 / 6;
  }
  .component-feature .feature-item {
    height: 250px;
  }
  .component-feature .feature-item-content {
    padding: 16px;
  }
  h4 {
    font-size: 16px;
  }
  .button-normal {
    padding: 8px 12px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }
  .component-feature .feature-item-content:hover .content__description {
    font-size: 14px;
  }
  .content__title {
    text-shadow: 0px 0px 4px black;
    font-size: 1.75rem;
    color: white;
  }
}
@media (max-width: 800px) {
  .component-feature .feature-box {
    grid-column: 1 / -1;
    margin-top: 20px;
  }
  .component-feature .feature-main {
    grid-template-columns: repeat(8, 1fr);
    padding: 0 20px;
    grid-column-gap: 8px;
  }
  .component-feature #feature-content {
    position: relative;
  }
  .component-feature .wrapper {
    display: flex;
    grid-gap: 0;
  }
  .component-feature .feature-item {
    height: 468px;
  }
  .component-feature .badge {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-around;
    grid-gap: 6.5px;
  }
  .component-feature .feature-item__video--mobile {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  .component-feature .feature-item-content {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 32px;
  }
  .component-feature .feature-item__img--desktop {
    display: none;
  }
  .component-feature .feature-item__img--mobile {
    display: block;
    object-fit: cover;
    height: 100%;
  }
  .content__title {
    text-align: center;
  }
  .content__description {
    opacity: 1;
    font-size: 22px;
    color: white;
    text-shadow: 0px 0px 4px black;
    text-align: left;
  }
  .component-feature .feature-item-content:hover .content__description {
    font-size: 22px;
  }
  .component-feature .badge__button {
    padding: 9px 12px;
    grid-gap: 4px;
    margin: 2px;
  }
  h5 {
    font-size: 18px;
    line-height: 21.6px;
    font-weight: 500;
  }
  h4 {
    font-size: 28px;
  }
  .badge {
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .component-feature .badge {
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }
  .component-feature .feature-main {
    padding: 0;
  }
  h4 {
    font-size: 24px;
  }
}
