@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mooli&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Acme&family=Concert+One&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Mooli&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bad+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Andika:ital,wght@0,400;0,700;1,400;1,700&family=Bad+Script&display=swap");

:root {
  --primary-color: #273d4b;
  --primary-color-700: #273d4b;
  --primary-color-300: #b7f1ea;
  --primary-color-100: #dcebe9;
  /* --secondary-color: #2bb569; */
  /* --secondary-color: #088395; */
  /* --secondary-color: #ff8784; */
  --secondary-color: #ec6c69;
  --secondary-color-700: #f3908e;
  --secondary-color-300: #ffd1d0;
  --secondary-color-100: #f4e8e8;
  --tertiary-color: #e9f4f3;
  --blue-color: #4cc3e5;
  --blue-color-700: #7ed4ed;
  /* --tertiary-color: #ff6f59; */
  --tertiary-color: #3e9ea7;
  --warning-color: #ffb516;
  --warning-color-700: #ffcf68;
  --warning-color-300: #ffe2a3;
  --warning-color-100: #f2efe9;
  --black-color: #000;
  --black-color-700: #00000075;
  --black-color-200: #00000025;
  --black-color-100: #0000001a;
  --gray-color-special: #78788029;
  --gray-color: #4b4b4b;
  --gray-color-700: #6d6d6d;
  --gray-color-500: #9a9a9a;
  --gray-color-300: #dddddd;
  --gray-color-100: #ebebef;
  --white-color: #fff;
  --night-color: #4e5a6b;
  scroll-behavior: smooth;
}
h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
img,
video {
  max-width: none !important;
}

p,
h1,
h2,
h3,
h4,
h5,
* {
  font-family: "Andika", "Noto Sans";
  /* 
  font-family: proxima-nova, sans-serif;
  font-family: Roboto;
  
  */
}
